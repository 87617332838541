module.exports = {
  siteTitle: 'Vale y Brian 2023', // <title>
  manifestName: 'Vale y Brian',
  manifestShortName: 'Vale y Brian', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#444444',
  manifestThemeColor: '#f09000',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/gatsby-starter-solidstate/`, // This path is subpath of your hosting https://domain/portfolio
  heading: '¡Nos casamos!',
  subHeading: '11 de Marzo, 2023',
  // social
  socialLinks: [
    // {
    //   icon: 'fa-instagram',
    //   name: 'Instagram',
    //   url: '#brianvale2020',
    // },
    // {
    //   icon: 'fa-twitter',
    //   name: 'Twitter',
    //   url: 'https://twitter.com/onlyanubhav',
    // },
    // {
    //   icon: 'fa-facebook',
    //   name: 'Facebook',
    //   url: 'https://facebook.com/theanubhav',
    // },
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:bcardiff@gmail.com',
      label: 'bcardiff@gmail.com',
    },
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:valetiff@gmail.com',
      label: 'valetiff@gmail.com',
    },
  ],
  // phone: '000-00000',
  address: 'Av. Costanera y Av. Sarmiento, CABA',
};
