import React from 'react';
import config from '../../config';
export default function Footer() {
  return (
    <section id="footer">
      <div className="inner">
        <h2 className="major">RSVP</h2>
        <p>
          Esperamos que puedas venir a festejar con nosotres.
        </p>
        <form name="rsvp" method="post" action="/rsvp" data-netlify="true" data-netlify-honeypot="bot-field">
          <input type="hidden" name="form-name" value="rsvp" />
          <p className="hidden"><input name="bot-field" /></p>
          <div className="fields">
            <div className="field">
              <label htmlFor="name">¿Quién sos? / ¿Quiénes son?</label>
              <input type="text" name="name" id="name" required={true} />
            </div>
            <div className="field">
              <label htmlFor="rsvp">¿Venís?</label>
              <select name="rsvp" id="rsvp" required={true}>
                <option value="">-</option>
                <option value="voy">Sí</option>
                <option value="no">No, me lo pierdo</option>
              </select>
            </div>
            <div className="field">
              <label htmlFor="music">¿Qué canción no puede faltarle al DJ?</label>
              <input type="text" name="music" id="music" />
            </div>
            <div className="field">
              <label htmlFor="message">Comentarios <span style={{ fontWeight: "normal" }}>como restricción dietaria</span></label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
          </div>
          <ul className="actions">
            <li>
              <input type="submit" value="Enviar" />
            </li>
          </ul>
        </form>
        <ul className="contact">
          <li className="fa-home"><a href="https://goo.gl/maps/a29SnebmpVHydesHA">{config.address}</a></li>

          {/* <li className="fa-phone">{config.phone}</li> */}

          {config.socialLinks.map(social => {
            const { icon, url, label } = social;
            return (
              <li className={`${icon}`} key={url}>
                <a href={url}>{label || url}</a>
              </li>
            );
          })}
        </ul>
        <ul className="copyright">
          <li>
            Design: <a href="http://html5up.net">HTML5 UP</a>
          </li>
        </ul>
      </div>
    </section>
  );
}
